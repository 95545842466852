<template>
  <el-container class="login">
    <el-main class="loginMain">
      <div class="content">
        <div class="main-left"></div>
        <div class="main-right">
          <div class="logo-img"></div>
          <el-form
            :model="loginForm"
            :rules="rules"
            class="login-form"
            ref="loginForm"
          >
            <!-- 账号 -->
            <el-form-item prop="account">
              <el-input
                class="username"
                v-model="loginForm.account"
                placeholder="请输入登录手机号"
                tabindex="1"
                autofocus="true"
              >
                <i slot="prefix" class="login-icon">
                  <svg-icon iconClass="user" style="font-size: 0.2rem" />
                </i>
              </el-input>
            </el-form-item>
            <!-- 登录密码 -->
            <el-form-item prop="password">
              <el-input
                class="password"
                v-model="loginForm.password"
                placeholder="请输入登录密码"
                type="password"
                autocomplete="new-password"
                tabindex="2"
              >
                <i slot="prefix" class="login-icon">
                  <svg-icon
                    iconClass="password"
                    style="font-size: 0.2rem"
                  /> </i
              ></el-input>
            </el-form-item>
            <el-row :lg="24">
              <el-form-item class="login-form-button">
                <el-button type="primary">立即登录</el-button>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
      <p class="agileits">
        版权所有 ©深圳市瑞麟数字科技控股有限公司
        <a href="https://beian.miit.gov.cn" target="_blank">
          粤ICP备2021043893号-1</a
        >
      </p>
    </el-main>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      reqCode: '',
      loginForm: {
        account: '',
        password: '',
        charVerityCode: '',
        charVerityUuid: '',
        tokenType: 'PC',
      },
      ifRemeberPwd: false,
      accountCopy: '',
      authFlag: 0,
      authStatus: 0,
      code: '',
      rules: {
        account: [
          {
            required: true,
            message: '请输入登录手机号',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur',
          },
          {},
        ],
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/login.scss';
.login {
  font-size: 14px;
}
.login-form-button {
  margin-top: 40px;
}
.agileits,
a {
  font-size: 12px;
  margin-top: 40px;
  color: #999999;
}
p.agileits a:hover {
  color: #7b97fd;
}
</style>
